import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Overview from "./pages/Overview/Overview";
import SearchDoctor from "./pages/SearchDoctor/SearchDoctor";
import StatusPage from "./pages/StatusPage/StatusPage";
import Test from "./pages/Test";
import ThemeProvider from "./theme";
import SignIn from "./pages/SignIn/SignIn";
import Registration from "./pages/Registration/Registration";
import SelectPatient from "./pages/SelectPatient/SelectPatient";
import ViewAppointments from "./pages/Home/ViewAppointments";
import { useEffect } from "react";
import { login } from "./app/apiManager/initialLogin";

function App() {
  useEffect(() => {
    initialLogin();
  }, []);

  const initialLogin = async () => {
    const response = await login({
      password: "nurse",
      userName: "nurse",
    });

    if (
      (response?.status === 1000 && response?.data?.UserTypeId === 4) ||
      response?.data?.UserGroupId === 11
    ) {
      const tokenData = {
        token: response?.data?.token,
        refreshToken: response?.data?.refreshToken,
      };
      
      localStorage.setItem("userData", JSON.stringify(tokenData));
    }
  };
  return (
    <div className="App">
      <ThemeProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="sign-in" element={<SignIn />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/select-patient" element={<SelectPatient />} />
              <Route path="/view-appointments" element={<ViewAppointments />} />
            </Route>
            <Route path="/app/overview" element={<Overview />} />
            <Route path="/search" element={<SearchDoctor />} />
            <Route path="/test" element={<Test />} />
            <Route path="/status" element={<StatusPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
