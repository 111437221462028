import { post } from "./apiManagerV2";

export const login = async ({ userId = false, userName, password }) => {
  const response = await post({
    path: "/Authenticate",
    requestBody: {
      UserId: userId,
      UserName: userName,
      Password: password,
    },
  });

  return response;
};
