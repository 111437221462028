import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCaptcha, verifyCaptcha } from "../../app/apiManager/apiManager";
import { verifyOTP } from "../../app/apiManager/otpServices";
import OTPTimer from "../../components/OTPTimer/OTPTimer";
import Subtitle from "../../components/Subtitle/Subtitle";
import { PRODUCTION } from "../../const";
import { setPhoneNo, setStep } from "../../reducers/appointmentInfoSlice";
import { generateRandomAlphanumerics } from "../../utils/captcha-text";

const StepTwo = ({
  setCaptchaVerified,
  btnText,
  setBtnText,
  setIsDisabled,
  isDisabled,
  captchaVerified,
}) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const verifyBtnRef = useRef();
  const otpRefs = [ref1, ref2, ref3, ref4, ref5, ref6];

  const onTypeOtp = (e, index) => {
    const { value } = e.target;
    // console.log("value", value);

    //if first value length equals 6 chars set all values
    if (value.length === 6 && index === 0) {
      setOtp(value);
      let values = value.split("");
      otpRefs.forEach((ref, index) => {
        ref.current.value = values[index];
      });
      verifyBtnRef.current.removeAttribute("disabled");
      verifyBtnRef.current.focus();
      return;
    }
    // console.log("value.length", value.length);
    if (value.length === 1) {
      let otpArray = otp.split("");
      otpArray[index] = value;
      setOtp(otpArray.join(""));
      if (index + 1 < 6) {
        otpRefs[index + 1].current.focus();
      } else {
        otpRefs[5].current.blur();
        verifyBtnRef.current.removeAttribute("disabled");

        // verifyBtnRef.current.focus();
      }
    } else if (value.length > 1) {
      //set the values again
      let otpArray = otp.split("");
      otpRefs[index].current.value = otpArray[index];
      if (index + 1 < 6) {
        otpRefs[index + 1].current.focus();
      } else {
        verifyBtnRef.current.removeAttribute("disabled");
        verifyBtnRef.current.focus();
      }
    }
  };

  const session = useSelector((state) => state.placeAppointment.session);

  const sessionId = session?.sessionId;
  const appointmentDateTime = session?.timeStart;
  const activeStep = useSelector((state) => state.appointmentInfo.step);

  // const phoneNo = useSelector((state) => state.appointmentInfo.phoneNo);
  const [phoneNo, setMobileNo] = useState("");
  const [captchaError, setCaptchaError] = useState({
    isVisible: false,
    message: "captcha codes does not match",
  });
  const [OTPError, setOTPError] = useState({
    isVisible: false,
    message: "OTP verification failed",
  });
  const [typedCaptcha, setTypedCaptcha] = useState("");
  const [captchaGUID, setCaptchaGUID] = useState("");
  const [otp, setOtp] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [captchaText, setCaptchaText] = useState(
    generateRandomAlphanumerics(6)
  );
  const dispatch = useDispatch();

  const [phoneNumberError, setPhoneNumberError] = useState({
    isVisible: false,
    message: "please enter your phone number",
  });

  const onClickSendOTP = async () => {
    if (!phoneNo.length > 0) {
      setPhoneNumberError({
        isVisible: true,
        message: "please enter your phone number",
      });
      return false;
    }
    if (
      !phoneNo.match(
        /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
      )
    ) {
      setPhoneNumberError({
        isVisible: true,
        message: "please enter a valid phone number",
      });
      return false;
    } else {
      setPhoneNumberError({
        isVisible: false,
        message: "please enter your phone number",
      });
    }

    if (typedCaptcha.length > 0) {
      setCaptchaError({
        isVisible: false,
        message: "please complete the captcha",
      });
    } else {
      setCaptchaError({
        isVisible: true,
        message: "please complete the captcha",
      });
      return false;
    }

    // await verifyCaptcha(
    //   captchaGUID,
    //   typedCaptcha,
    //   (data) => {

    //   },
    //   () => {
    //     return false;
    //   }
    // );
    const isCaptchaVerified = typedCaptcha === captchaText;

    console.log(typedCaptcha,captchaText)
    if (isCaptchaVerified) {
      setCaptchaVerified(true);
      // setTypedCaptcha("");
      //clear all otp fields
      otpRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
        }
      });
      setOtp("");
      setCaptchaError({
        isVisible: false,
        message: "captcha codes does not match",
      });
      otpRefs[0].current.focus();
    } else {
      setCaptchaError({
        isVisible: true,
        message: "captcha codes does not match",
      });
      setCaptchaVerified(false);
    }
  };

  useEffect(() => {
    if (activeStep === 1) {
      // //clear all otp fields
      // otpRefs.forEach((ref) => {
      //   if (ref.current) {
      //     ref.current.value = "";
      //   }
      // });
      // setOtp("");
      setCaptcha(false);
      getCaptcha(
        (data) => {
          setCaptchaGUID(data.Guid);
          setCaptcha(data.Base64);
        },
        () => {}
      );
    }
  }, [activeStep]);

  const checkAndVerifyOTP = () => {
    if (PRODUCTION) {
      verifyOTP(
        phoneNo,
        otp,
        (data, response) => {
          if (response.Message === "Success") {
            setOTPError({
              isVisible: false,
              message: "OTP verification failed",
            });
            dispatch(setPhoneNo({ phoneNo: phoneNo }));
            dispatch(
              setStep({
                step: 3,
              })
            );
          } else {
            setOTPError({
              isVisible: true,
              message: "OTP verification failed",
            });
          }
        },
        () => {}
      );
    } else {
      dispatch(
        setStep({
          step: 3,
        })
      );
    }
  };

  return (
    <Grid item xs={12} sm lg sx={{ minHeight: "30rem" }}>
      <>
        <Subtitle
          title={"Your Info"}
          sessionId={sessionId}
          appointmentDateTime={appointmentDateTime}
        />
        <Box mt={1}>
          <Box display={"flex"} alignItems="end">
            <Typography fontWeight={"bold"}>Enter mobile number</Typography>
            {phoneNumberError.isVisible && (
              <Typography
                sx={{
                  ml: 1,
                  fontSize: "0.8rem",
                  color: "error.main",
                }}
              >
                *{phoneNumberError.message}
              </Typography>
            )}
          </Box>
          <Box mt={1}>
            <TextField
              placeholder={"07X XXX XXX"}
              value={phoneNo}
              onChange={(event) => {
                setMobileNo(event.target.value);
              }}
              fullWidth
              size="small"
            />
          </Box>
        </Box>
        <Box display={"flex"} alignItems="end">
          <Typography sx={{ mt: 2, mb: 1 }} fontWeight={"bold"}>
            Solve Captcha
          </Typography>
          {captchaError.isVisible && (
            <Typography
              sx={{
                ml: 1,
                mt: 2,
                mb: 1,
                fontSize: "0.8rem",
                color: "error.main",
              }}
            >
              *{captchaError.message}
            </Typography>
          )}
        </Box>

        <Grid container alignItems={"center"} gap={2}>
          <Grid item xs>
            <TextField
              type={"text"}
              fullWidth
              size="small"
              value={typedCaptcha}
              onChange={(event) => {
                setTypedCaptcha(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs>
            {/* {captcha ? (
              <img
                src={`data:image/jpeg;base64,${captcha}`}
                alt=""
                height={"40px"}
              />
            ) : (
              <CircularProgress size={34} />
            )} */}
            {/* <LoadCanvasTemplate /> */}

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="10px"
              py={0.5}
              borderRadius="8px"
              width="200px"
              bgcolor="#f9f9f9"
              onContextMenu={(e) => e.preventDefault()} // Disable right-click
              onMouseUp={(e) => e.preventDefault()}
              sx={{
                cursor: "not-allowed",
              }}
            >
              <Typography
                variant="h6"
                fontFamily="'Courier New', Courier, monospace"
                sx={{
                  letterSpacing: "2px",
                  fontWeight: "bold",
                  userSelect: "none", // Prevent text selection
                  pointerEvents: "none", // Prevent any interaction with the text
                }}
              >
                {captchaText}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent="start" mt={2}>
          <OTPTimer
            onClick={onClickSendOTP}
            btnText={btnText}
            setCaptchaVerified={setCaptchaVerified}
            setBtnText={setBtnText}
            phoneNo={phoneNo}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            captchaVerified={captchaVerified}
          />
        </Box>

        <Box mt={2}>
          <Box display={"flex"} alignItems="end">
            <Typography fontWeight={"bold"}>Enter OTP number</Typography>
            <Typography
              sx={{
                ml: 1,
                fontSize: "0.8rem",
                color: "error.main",
              }}
            >
              {OTPError.isVisible && (
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "0.8rem",
                    color: "error.main",
                  }}
                >
                  *{OTPError.message}
                </Typography>
              )}
            </Typography>
          </Box>
          <Box
            mt={1}
            display="flex"
            gap={2}
            sx={{
              height: "3rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <TextField
              placeholder={"XXXX"}
              onChange={(event) => {
                setOtp(event.target.value);
              }}
              value={otp}
              fullWidth
              size="small"
            /> */}
            {otpRefs.map((ref, index) => (
              <TextField
                key={index}
                inputRef={ref}
                size="small"
                sx={{
                  "& .MuiInputBase-inputSizeSmall": {
                    padding: "0.5rem",
                    textAlign: "center",
                  },
                }}
                onChange={(e) => {
                  onTypeOtp(e, index);
                }}
              />
            ))}

            <Button
              variant="contained"
              // {...(activeStep !== 2 && {
              //   disabled: true,
              // })}
              {...(ref6.current &&
                otp.length !== 6 && {
                  disabled: true,
                })}
              onClick={checkAndVerifyOTP}
              ref={verifyBtnRef}
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              Verify
            </Button>
          </Box>
          <Button
            variant="contained"
            // {...(activeStep !== 2 && {
            //   disabled: true,
            // })}
            {...(ref6.current &&
              otp.length !== 6 && {
                disabled: true,
              })}
            onClick={checkAndVerifyOTP}
            ref={verifyBtnRef}
            sx={{
              mt: 1,
              display: { md: "none" },
            }}
          >
            Verify
          </Button>
        </Box>
      </>
    </Grid>
  );
};

export default StepTwo;
