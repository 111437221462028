import { post } from "./apiManagerV2";

export const validateCoupon = async ({ couponCode, patientId }) => {
  const response = await post({
    path: "/coupons/check-validity",
    requestBody: {
      CouponCode: couponCode,
      PatientId: patientId,
    },
  });

  return response;
};

export const redeemCoupon = async ({ couponCode, patientId }) => {
  const response = await post({
    path: "/coupons/redeem",
    requestBody: {
      CouponCode: couponCode,
      PatientId: patientId,
    },
  });

  return response;
};
