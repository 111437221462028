import { post, postAsyncV2 } from "./apiManager";

export const sendOTP = (phoneNumber, onSuccess, onFailed) => {
  fetch(`https://bridge.medica.lk/OTP`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      UserId: "2",
    },
    body: JSON.stringify({
      MobileNumber: phoneNumber,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data.Data, data);
    }).catch((error) => {
      onFailed(error);
    });
  // post(
  //   `OTP`,
  //   {
  //     MobileNumber: phoneNumber,
  //   },
  //   "sendOTP",
  //   onSuccess,
  //   onFailed
  // );
};

export const sendOtpV2 = async (mobileNumber) => {
  const response = await postAsyncV2("/OTP", {
    MobileNumber: mobileNumber,
  });
  const data = await response.json();
  return data;
};

export const verifyOTP = (phoneNumber, otpValue, onSuccess, onFailed) => {
  post(
    `OTP`,
    {
      MobileNumber: phoneNumber,
      OTPValue: otpValue,
    },
    "verifyOTP",
    onSuccess,
    onFailed
  );
};

export const verifyOtpV2 = async (mobileNumber, otpValue) => {
  const response = await postAsyncV2("/OTP", {
    MobileNumber: mobileNumber,
    OTPValue: otpValue,
  });
  const data = await response.json();
  if (data.Message === "Success") {
    return true;
  }
  return false;
  //  const data = await response.json();
  //  return data;
};
