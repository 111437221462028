import { createSlice } from "@reduxjs/toolkit";

export const consultationFeeSlice = createSlice({
  name: "consultationFee",
  initialState: {
    consultationFee: {},
  },
  reducers: {
    setConsultationFee: (state, action) => {
      state.consultationFee = action.payload;
    },
  },
});

export const { setConsultationFee } = consultationFeeSlice.actions;
export default consultationFeeSlice.reducer;
