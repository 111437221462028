import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseUrl,
});

const refreshToken = async () => {
  try {
    const response = await api.post("/RefreshToken", {
      Token: JSON.parse(localStorage.getItem("userData")).refreshToken,
    });
    if (response?.status === 200) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const newToken = response.data.data.token; // Assuming the new token is in the response
      const newRefreshToken = response.data.data.refreshToken;
      userData.token = newToken;
      userData.refreshToken = newRefreshToken;
      localStorage.setItem("userData", JSON.stringify(userData));
      return newToken;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
};

// Request interceptor to add Authorization header
api.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = userData?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors and retry request
api.interceptors.response.use(
  (response) => {
    // If the response is successful, return it
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to token expiration (401) and if we haven't retried yet
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried

      // Attempt to refresh the token
      const newToken = await refreshToken();
      if (newToken) {
        // Update the Authorization header with the new token
        axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

        // Retry the original request
        return axios(originalRequest);
      } else {
        // If refreshing the token fails, redirect to login or handle session expiration
        // handleSessionExpired();
        // localStorage.removeItem("userData");
        // window.location.href = "/";
      }
    }

    return Promise.reject(error);
  }
);

// POST request function
export const post = async ({
  path,
  requestBody = {},
  header = {},
  hideUserId = false,
  isFormData = false,
}) => {
  try {
    let UserId = 2;
    if (UserId) {
      UserId = parseInt(UserId);
    }
    if (isFormData) {
      requestBody.append("UserId", UserId);
    }
    const response = await api.post(
      path,
      hideUserId
        ? requestBody
        : isFormData
        ? requestBody
        : { UserId, ...requestBody },
      {
        headers: {
          "Content-Type": isFormData
            ? "multipart/form-data"
            : "application/json",
          ...header,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    let errorMessage = "An unknown error occurred";
    if (error?.response) {
      console.log("Error data:", error?.response?.data); // The response body
      errorMessage = error?.response?.data?.error;
      if (!(errorMessage instanceof String) && error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message;
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log("Error request:", error?.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error message:", error?.message);
    }
    // return error?.response?.data;
    throw Error(errorMessage);
  }
};

// GET request function
export const get = async ({ path, header = {} }) => {
  const response = await api.get(path, {
    headers: {
      "Content-Type": "application/json",
      ...header,
    },
  });
  return response?.data;
};
