import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/background.png";
import DoctorDetails from "../../components/DoctorDetails/DoctorDetails";
import Titlebar from "../../components/Titlebar/Titlebar";
import {
  clearAll,
  saveSteps,
  setAppointmentId,
  setPaymentDetails,
  setSelectedDoctor,
  setSessionExpireOn,
  setStep,
} from "../../reducers/appointmentInfoSlice";

import { checkOrder, getIpInfo } from "../../app/apiManager/apiManager";
import {
  getAllDoctorsByInstitute,
  getDoctorPhoneNumber,
  getDoctorPhoneNumberV2,
  getDoctorSessions,
  getDoctorSpecialization,
} from "../../app/apiManager/doctorServices";

import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { format } from "date-fns";
import { useRef } from "react";
import { MutatingDots } from "react-loader-spinner";
import { Outlet } from "react-router-dom";
import { placeAppointment } from "../../app/apiManager/appointmentService";
import {
  sendNotification,
  sendNotificationV2,
} from "../../app/apiManager/notificationServices";
import appointmentIcon from "../../assets/appintment-icon.png";
import contactUs from "../../assets/contact-us.png";
import logo from "../../assets/logo.png";
import medica from "../../assets/medica.png";
import medicaLogo from "../../assets/medicaLogo.png";
import CustomModal from "../../components/CustomModal/CustomModal";
import PageMenu from "../../components/PageMenu/PageMenu";
import { PRODUCTION } from "../../const";
import { getExpiry, isExpired } from "../../utils/expire";
import getOriginalDateTime from "../../utils/formatAMPM";
import { formattedString } from "../../utils/formattedString";
import AddPatient from "./AddPatient";
import ModalLoading from "./ModalLoading";
import PaymentFailed from "./PaymentFailed";
import PaymentSuccess from "./PaymentSuccess";
import SearchDoctor from "./SearchDoctor";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import ViewAppointments from "./ViewAppointments";
import { setConsultationFee } from "../../reducers/consultationFeeSlice";
import { redeemCoupon } from "../../app/apiManager/couponService";

const sendNotificationToDoctor = async (
  doctorId,
  appointmentNumber,
  appointmentTime
) => {
  const doctorPhoneNumber = await getDoctorPhoneNumberV2(doctorId);
  sendNotification(
    {
      ScheduleMediums: [
        {
          MediumId: 1,
          Destination: doctorPhoneNumber,
          Status: 0,
        },
      ],
      NotifactionType: 2,
      Message: formattedString(process.env.REACT_APP_MESSAGE_TO_DOCTOR, [
        appointmentNumber,
        `${appointmentTime.toDateString()}`,
        `${format(appointmentTime, "p")}`,
        process.env.REACT_APP_VC_DOMAIN,
      ]),
      Status: 0,
    },
    (data) => {},
    (error) => {}
  );
};

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [viewOngoingAppointments, setViewOngoingAppointments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const doctor = useSelector((state) => state.placeAppointment.doctor);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const coupon = useSelector((state) => state.consultationFee.consultationFee);

  const activeStep = useSelector((state) => state.appointmentInfo.step);
  const appointmentId = useSelector(
    (state) => state.appointmentInfo.appointmentId
  );
  const selectedDoctor = useSelector(
    (state) => state.appointmentInfo.selectedDoctor
  );
  const sessionExpireOn = useSelector(
    (state) => state.appointmentInfo.sessionExpireOn
  );

  const txnId = useSelector((state) => state.appointmentInfo.txnId);
  const reference = useSelector((state) => state.appointmentInfo.reference);
  const sessionId = useSelector(
    (state) => state.placeAppointment.session.sessionId
  );
  const appointmentDateTime = useSelector(
    (state) => state.placeAppointment.session.timeStart
  );
  const patientId = useSelector((state) => state.appointmentInfo.patientId);
  const patientMobileNo = useSelector((state) => state.appointmentInfo.phoneNo);
  const patientEmail = useSelector(
    (state) => state.appointmentInfo.patientEmail
  );

  // ------------------------ use states ---------------------------

  const [availableSessions, setAvailableSessions] = useState([]);
  const [typedCaptcha, setTypedCaptcha] = useState("");
  const dispatch = useDispatch();
  const [refreshCalendar, setRefreshCalendar] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState({
    isVisible: false,
    content: "",
  });

  const [showing, setShowing] = useState(0);
  const [btnText, setBtnText] = useState("Send OTP");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOtherCountry, setIsOtherCountry] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  // ------------------------ use states ---------------------------

  const [doctors, setDoctors] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [doctorPhoneNo, setDoctorPhoneNo] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorId, setDoctorId] = useState("");

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [numberCopied, setNumberCopied] = useState(false);
  const hotlineRef = useRef(null);
  const hotlineBtnRef = useRef(null);
  const [specialities, setSpecialities] = useState([
    "Counselling",
    "General Health & Wellness Clinic",
    "Online Video Consultation",
    "Diabetic Foot & Wound Care Center",
  ]);
  const mobile = useSelector((state) => state.signIn.Mobile);
  const [doctorFilterObj, setDoctorFilterObj] = useState({
    doctorName: "",
    specialization: "",
    date: "",
  });

  useEffect(() => {
    getAllDoctorsByInstitute(
      (data) => {
        data.map(function (e, i) {
          i++;
          if (i <= 5) {
            e = {
              ...e,
              imageLink: `${process.env.REACT_APP_VC_DOMAIN}${process.env.REACT_APP_IMAGE_PATH}${e.Id}.png`,
            };
            setDoctors((prev) => [...prev, e]);
          }
        });
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    dispatch(
      setConsultationFee({
        totalFee:
          doctor.doctorFee +
          doctor.hospitalFee +
          doctor.otherFee +
          doctor.serviceFee,
      })
    );
  }, [doctor]);

  const toggleContactUs = () => {
    setShowContactUs(!showContactUs);
  };

  const calculateEstimatedAppointmentStartTime = (dateTime) => {
    let dateTimeCopy = new Date(dateTime);
    dateTimeCopy = dateTimeCopy.setMinutes(dateTimeCopy.getMinutes() + 15);
    return dateTimeCopy;
  };

  const makeAppontment = (paymentType) => {
    placeAppointment(
      {
        SessionId: sessionId,
      },
      (nextAppointment) => {
        placeAppointment(
          {
            Description: "",
            Id: 0,
            Number: nextAppointment.Number,
            PatientId: patientId,
            SessionId: sessionId,
            Status: paymentType,
            UserId: 14,
          },
          (data) => {
            //send notification to the patient
            let appointmentTime = getOriginalDateTime(appointmentDateTime);
            let appointmentDoctorName = data?.DoctorName;
            let appointmentDoctorId = data?.DoctorId;
            let appointemntNumber = data?.Number;
            //  let endDateTime = new Date(data.TimeEnd);

            dispatch(
              setAppointmentId({
                appointmentId: data.Id,
              })
            );

            //send notification to doctor
            if (PRODUCTION) {
              // sendNotificationToDoctor(
              //   appointmentDoctorId,
              //   appointemntNumber,
              //   appointmentTime
              // );

              // send notification to patient
              // sendNotification(
              //   {
              //     ScheduleMediums: [
              //       {
              //         MediumId: 1,
              //         Destination: patientMobileNo,
              //         Status: 0,
              //       },
              //     ],
              //     NotifactionType: 2,
              //     Message: formattedString(
              //       process.env.REACT_APP_MESSAGE_TO_PATIENT,
              //       [
              //         appointmentDoctorName,
              //         data.Number,
              //         `${appointmentTime.toDateString()}`,
              //         `${format(appointmentTime, "p")}`,
              //         `${
              //           data.Number === 1
              //             ? format(appointmentTime, "p")
              //             : format(
              //                 calculateEstimatedAppointmentStartTime(
              //                   appointmentTime
              //                 ),
              //                 "p"
              //               )
              //         }`,
              //         `${process.env.REACT_APP_DOMAIN}status?appointmentId=${data.Id}`,
              //       ]
              //     ),
              //     Status: 0,
              //   },
              //   (data) => {},
              //   (error) => {
              //     // console.log(error);
              //   }
              // );

              let encrypotedData = `${
                process.env.REACT_APP_INSTITUTE_ID
              }||${patientMobileNo}||${formattedString(
                process.env.REACT_APP_MESSAGE_TO_PATIENT,
                [
                  appointmentDoctorName,
                  data.Number,
                  `${appointmentTime.toDateString()}`,
                  `${format(appointmentTime, "p")}`,
                  `${
                    data.Number === 1
                      ? format(appointmentTime, "p")
                      : format(
                          calculateEstimatedAppointmentStartTime(
                            appointmentTime
                          ),
                          "p"
                        )
                  }`,
                  `${process.env.REACT_APP_DOMAIN}status?appointmentId=${data.Id}`,
                ]
              )}`;

              const encoded = window.enMsg(encrypotedData);

              sendNotificationV2(
                encoded,
                (data) => {},
                (error) => {
                  console.log(error);
                }
              );
            }

            navigate(`/status?appointmentId=${data.Id}`);
          },
          (error) => {
            setPaymentStatus({
              isVisible: false,
              content: "",
            });
            alert("error");
            console.log(error);
          }
        );
      },
      (error) => {}
    );
  };

  const couponRedeem = async () => {
    if (coupon.coupon) {
      const response = await redeemCoupon({
        couponCode: coupon.coupon.couponCode,
        patientId: patientId,
      });
      console.log("redeem coupon response", response);
    }
  };

  useEffect(() => {
    getIpInfo(
      (d) => {
        if (d.country_code != "LK") {
          setIsOtherCountry(true);
        }
      },
      (er) => {}
    );

    //check payment status if the txnid is present in the redux store
    if (txnId.length > 0) {
      setPaymentStatus({
        isVisible: true,
        content: "loading",
      });
      checkOrder(
        reference,
        txnId,
        isOtherCountry,
        (data) => {
          let result = data.data.data.summaryResult;
          if (result) {
            if (result === "SUCCESS") {
              dispatch(clearAll());
              makeAppontment(2);
              setPaymentStatus({
                isVisible: true,
                content: "success",
              });
              couponRedeem();
            }
            if (result === "FAILURE") {
              setPaymentStatus({
                isVisible: true,
                content: "failed",
              });
            }
          }
        },
        () => {}
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (doctors.length > 0) {
      // change 2 to 0 to length
      let doctor = doctors.filter((doctor) => doctor.Id === selectedDoctor)[0];

      const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      const localISOTime = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, -5);

      if (doctor) {
        setDoctorName("");
        setSpecialization("");
        setDoctorId(doctor.Id);
        getDoctorSessions(
          selectedDoctor,
          localISOTime,
          3,
          (data) => {
            setAvailableSessions(data);
            // setRefreshCalendar(true);
          },
          () => {}
        );
        getDoctorSpecialization(
          selectedDoctor,
          (data) => {
            //setting doctor name and specialization
            setDoctorName(doctor.FirstName + " " + doctor.LastName);
            setSpecialization(data[0].SpecializationName);
          },
          () => {}
        );
        getDoctorPhoneNumber(
          selectedDoctor,
          (data) => {
            setDoctorPhoneNo(data[0].ContactNumber);
          },
          () => {}
        );
      } else {
        //select first doctor
        dispatch(setSelectedDoctor({ selectedDoctor: doctors[0].Id }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctor, doctors]);

  useEffect(() => {
    if (activeStep === 0) {
      setRefreshCalendar(true);
    }
    if (activeStep === 1 && patientMobileNo !== "") {
      dispatch(setStep({ step: 3 }));
    }
  }, [activeStep]);

  if (isExpired(new Date(sessionExpireOn))) {
    dispatch(clearAll());
    dispatch(setSessionExpireOn({ sessionExpireOn: getExpiry(1) }));
  }

  useEffect(() => {
    let timeout = null;

    if (numberCopied) {
      timeout = setTimeout(() => {
        setNumberCopied(false);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [numberCopied]);

  const navigateViewAppointments = () => {
    if (mobile !== "") {
      navigate("/view-appointments");
      return;
    }
    navigate("/sign-in", { state: { nextRoute: "/view-appointments" } });
  };

  const stepOneRef = useRef(null);

  const performGasAuth = async () => {
    // const token = await getToken();
    // const browserId = await getBrowserId(token);
    // console.log(token);
    // console.log(browserId);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: isLoading ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 2,
          backgroundColor: "rgba(255,255,255)",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            paddingTop: "40px",
          }}
        >
          <img src={logo} alt="logo" width={"400px"} />
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <MutatingDots
            height="100"
            width="100"
            color={theme.palette.primary.main}
            secondaryColor="#5BE584"
            radius="13.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <Typography variant={"h6"}>Loading ...</Typography>
        </div>

        <div
          style={{
            paddingBottom: "30px",
          }}
        >
          <Typography textAlign={"center"}>Powered By</Typography>
          <img
            src={medica}
            alt="medica logo"
            width={"100px"}
            style={{
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <CustomModal open={paymentStatus.isVisible}>
          {paymentStatus.content === "loading" ? <ModalLoading /> : null}
          {paymentStatus.content === "success" ? (
            <PaymentSuccess
              onClick={() => {
                setPaymentStatus({ isVisible: false, content: "" });
                dispatch(
                  setStep({
                    step: 0,
                  })
                );
                navigate(`/status?appointmentId=${appointmentId}`);
              }}
            />
          ) : null}
          {paymentStatus.content === "failed" ? (
            <PaymentFailed
              onClick={() => {
                dispatch(
                  setPaymentDetails({
                    txnId: "",
                    reference: "",
                  })
                );
                // dispatch(
                //   setStep({
                //     step: 0,
                //   })
                // );
                // navigate(`/status?appointmentId=${appointmentId}`);
                setPaymentStatus({ isVisible: false, content: "" });
              }}
            />
          ) : null}
        </CustomModal>

        <CustomModal open={showContactUs}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Need help with anything? Please contact us
          </Typography>

          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                Name
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter your name"
                sx={{
                  mt: 1,
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                Phone Number
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter your phone number"
                sx={{
                  mt: 1,
                }}
              />
            </Grid>
          </Grid>
          <Typography
            sx={{
              mt: 2,
            }}
          >
            Email Address
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Enter your email address"
            sx={{
              mt: 1,
            }}
          />
          <Typography
            sx={{
              mt: 2,
            }}
          >
            What do you need help with?
          </Typography>
          <Select sx={{ mt: 0.5 }} fullWidth size="small" defaultValue={""}>
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Mr.">I could not place the appointment</MenuItem>
            <MenuItem value="Mrs.">
              I did not received a confirmation message
            </MenuItem>
            <MenuItem value="Ms.">Could not find sessions</MenuItem>
            <MenuItem value="Baby">I can not see my family members</MenuItem>
            <MenuItem value="Dr.">Not listed here</MenuItem>
          </Select>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              width={"100%"}
              gap={0.5}
            >
              <img
                src={logo}
                alt="docnote logo"
                width={isDesktop ? "70px" : "50px"}
              />
              <Divider
                orientation="vertical"
                sx={{
                  mt: 0.5,
                  width: "2px",
                  height: "15px",
                  backgroundColor: "#ABABAB",
                }}
              />
              <img
                src={medica}
                alt="medica logo"
                width={isDesktop ? "90px" : "60px"}
              />
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Button variant="outlined" onClick={toggleContactUs}>
                Cancel
              </Button>
              <Button variant="contained">Submit</Button>
            </Box>
          </Box>
        </CustomModal>

        {/* <SignIn /> */}

        <Grid
          item
          xs={12}
          sm={11}
          md={11}
          lg={7}
          xl={6}
          sx={{
            backgroundColor: "rgba(240,240,240,0.95)",
            position: "relative",
            borderRadius: { xs: 0, lg: 3 },
            p: { xs: 0, lg: 0 },
            pt: 2,
            border: "1px solid #BFBFBF",
            minHeight: { xs: "auto", sm: "35rem", lg: "35rem" },
            maxHeight: { xs: "auto", sm: "40rem", lg: "35rem" },
            my: { xs: 0, lg: 0 },
          }}
        >
          <PageMenu
            isOpen={sideMenuOpen}
            setOpen={setSideMenuOpen}
            showing={showing}
            setShowing={setShowing}
          />
          <Box
            ref={stepOneRef}
            sx={{
              p: {
                xs: 2,
                sm: 3,
                md: 4,
                lg: 4,
              },
            }}
          >
            <>
              <Titlebar
                title={"Online Doctor Channeling"}
                onMenuClick={() => {
                  setSideMenuOpen(true);
                }}
                backClickAction={() => {
                  if (activeStep === 5) {
                    dispatch(setStep({ step: 3 }));
                  } else if (activeStep === 4) {
                    dispatch(setPaymentDetails({ txnId: "", reference: "" }));
                    dispatch(setStep({ step: activeStep - 1 }));
                  } else if (activeStep === 3 || activeStep === 2) {
                    setBtnText("Send OTP");
                    setIsDisabled(false);
                    setCaptchaVerified(false);
                    setTypedCaptcha("");
                    if (activeStep === 3 && patientMobileNo !== "") {
                      dispatch(setStep({ step: 0 }));
                    } else {
                      dispatch(setStep({ step: 1 }));
                    }
                  } else {
                    dispatch(setStep({ step: activeStep - 1 }));
                  }
                }}
              />

              <Grid container>
                {/* step 01 */}
                {activeStep === 0 && (
                  <StepOne
                    setDoctorFilterObj={setDoctorFilterObj}
                    specialities={specialities}
                  />
                )}

                {/* step 02 */}
                {(activeStep === 1 || activeStep === 2) && (
                  <StepTwo
                    setCaptchaVerified={setCaptchaVerified}
                    btnText={btnText}
                    setBtnText={setBtnText}
                    setIsDisabled={setIsDisabled}
                    isDisabled={isDisabled}
                    captchaVerified={captchaVerified}
                  />
                )}

                {/* step 03 */}
                {activeStep === 3 && <StepThree />}

                {/* step 04 */}
                {activeStep === 4 && (
                  <StepFour
                    setShowing={setShowing}
                    setSideMenuOpen={setSideMenuOpen}
                    setPaymentStatus={setPaymentStatus}
                    doctorPhoneNo={doctorPhoneNo}
                    doctorName={doctorName}
                    doctorId={doctorId}
                    isOtherCountry={isOtherCountry}
                    makeAppontment={makeAppontment}
                    doctor={doctor}
                  />
                )}

                {/* New Patient*/}
                {activeStep === 5 && <AddPatient />}

                {/* Right Side menu */}
                {activeStep !== 0 && (
                  <Box
                    pt={2}
                    ml={2}
                    sx={{
                      display: { xs: "none", sm: "unset", lg: "unset" },
                      height: activeStep === 0 ? "23rem" : "30rem",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: "1px",
                        height: "100%",
                        backgroundColor: "#ABABAB",
                      }}
                    />
                  </Box>
                )}
                <Grid item xs sm position={"relative"}>
                  <Box
                    sx={{ display: { xs: "unset", sm: "none", lg: "none" } }}
                  >
                    <Divider
                      orientation="horizontal"
                      sx={{
                        backgroundColor: "#ABABAB",
                        mt: 3,
                      }}
                    />
                  </Box>
                  {activeStep === 0 ? (
                    <SearchDoctor
                      setSpecialities={setSpecialities}
                      doctorFilterObj={doctorFilterObj}
                      setDoctorFilterObj={setDoctorFilterObj}
                    />
                  ) : (
                    <DoctorDetails
                      doctor={doctor}
                      doctorName={
                        doctor.title +
                        " " +
                        doctor.firstName +
                        " " +
                        doctor.lastName
                      }
                      doctorId={doctor.doctorId}
                      qualification={doctor.specialization}
                      regtistrationNo={doctor.registrationNumber}
                      languages={"Sinhala/English/Tamil"}
                      email={doctor.email}
                      fee={500}
                      action={() => {
                        dispatch(
                          saveSteps({
                            step: 1,
                            name: "",
                            specialization: "",
                            date: "",
                          })
                        );
                      }}
                    />
                  )}

                  {activeStep !== 0 && (
                    <Box
                      sx={{
                        mt: { xs: 2, sm: 0, lg: 0 },
                        pl: {
                          xs: 0,
                          sm: 2,
                        },
                        position: {
                          xs: "unset",
                        },
                        bottom: 30,
                      }}
                    >
                      <a
                        href="tel:0771771115"
                        style={{
                          display: "none",
                        }}
                        ref={hotlineRef}
                      >
                        call 07717771115
                      </a>

                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        sx={{
                          position: {
                            xs: "unset",
                            lg: "absolute",
                          },
                          bottom: {
                            xs: "unset",
                            lg: 10,
                          },
                          width: {
                            xs: "unset",
                            sm: "100%",
                          },
                        }}
                      >
                        <Button
                          aria-describedby={"id-popover"}
                          variant="text"
                          ref={hotlineBtnRef}
                          startIcon={<PhoneInTalkIcon />}
                          sx={{
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.8rem",
                              lg: "0.8rem",
                            },
                          }}
                          onClick={() => {
                            setNumberCopied(true);
                            if (matches) {
                              hotlineRef.current.click();
                            }
                            navigator.clipboard.writeText("0703304304");
                          }}
                          title={
                            numberCopied
                              ? "Number copied to clipboard"
                              : "Click to copy number"
                          }
                        >
                          Hotline 0776890900
                        </Button>

                        <Box position={"absolute"} bottom={55}>
                          {numberCopied ? (
                            <Box position={"relative"}>
                              <Typography
                                sx={{
                                  borderRadius: 1,
                                  p: 1,
                                  textAlign: "center",
                                  backgroundColor: "#0a1309",
                                  color: "#fff",
                                  fontSize: "0.8rem",
                                }}
                              >
                                Number copied to clipboard
                              </Typography>
                              <div
                                className="popover-point"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  position: "absolute",
                                  bottom: -5,
                                  left: "50%",
                                  transform: `rotate(45deg)`,
                                  backgroundColor: "#0a1309",
                                }}
                              ></div>
                            </Box>
                          ) : null}
                        </Box>

                        <Box display={"flex"}>
                          <Button
                            sx={{
                              p: 1,
                              minWidth: "unset",
                            }}
                            title="View your appointments"
                            onClick={navigateViewAppointments}
                          >
                            <img
                              src={appointmentIcon}
                              alt="whatsapp"
                              width={"25px"}
                            />
                          </Button>
                          <Button
                            sx={{
                              p: 1,
                              minWidth: "unset",
                            }}
                            title="Need any help? Contact us"
                            onClick={toggleContactUs}
                          >
                            <img
                              src={contactUs}
                              alt="whatsapp"
                              width={"28px"}
                            />
                          </Button>
                          <Button
                            sx={{
                              p: 1,
                              minWidth: "unset",
                            }}
                            title="Need any help? Contact us"
                            onClick={() => {}}
                          >
                            <img
                              src={medicaLogo}
                              alt="whatsapp"
                              width={"28px"}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>

              {/* Right Side menu end */}

              {activeStep === 0 && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  sx={{
                    mt: {
                      xs: 2,
                      sm: 0,
                    },
                  }}
                >
                  <div>
                    <img
                      src={medica}
                      alt="medica logo"
                      width={isDesktop ? "100px" : "70px"}
                      style={{
                        marginTop: "1rem",
                      }}
                    />
                  </div>
                  <div>
                    <a
                      href="tel:0771771115"
                      style={{
                        display: "none",
                      }}
                      ref={hotlineRef}
                    >
                      call 07717771115
                    </a>

                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Button
                        aria-describedby={"id-popover"}
                        variant="text"
                        ref={hotlineBtnRef}
                        startIcon={<PhoneInTalkIcon />}
                        sx={{
                          fontSize: {
                            xs: "0.7rem",
                            sm: "0.8rem",
                            lg: "0.8rem",
                          },
                        }}
                        onClick={() => {
                          setNumberCopied(true);
                          if (matches) {
                            hotlineRef.current.click();
                          }
                          navigator.clipboard.writeText("0703304304");
                        }}
                        title={
                          numberCopied
                            ? "Number copied to clipboard"
                            : "Click to copy number"
                        }
                      >
                        Hotline 0776890900
                      </Button>

                      <Box position={"absolute"} bottom={55}>
                        {numberCopied ? (
                          <Box position={"relative"}>
                            <Typography
                              sx={{
                                borderRadius: 1,
                                p: 1,
                                textAlign: "center",
                                backgroundColor: "#0a1309",
                                color: "#fff",
                                fontSize: "0.8rem",
                              }}
                            >
                              Number copied to clipboard
                            </Typography>
                            <div
                              className="popover-point"
                              style={{
                                width: "10px",
                                height: "10px",
                                position: "absolute",
                                bottom: -5,
                                left: "50%",
                                transform: `rotate(45deg)`,
                                backgroundColor: "#0a1309",
                              }}
                            ></div>
                          </Box>
                        ) : null}
                      </Box>

                      <Box display={"flex"}>
                        <Button
                          sx={{
                            p: 1,
                            minWidth: "unset",
                          }}
                          title="View your appointments"
                          onClick={navigateViewAppointments}
                        >
                          <img
                            src={appointmentIcon}
                            alt="whatsapp"
                            width={"25px"}
                          />
                        </Button>
                        <Button
                          sx={{
                            p: 1,
                            minWidth: "unset",
                          }}
                          title="Need any help? Contact us"
                          onClick={toggleContactUs}
                        >
                          <img src={contactUs} alt="whatsapp" width={"28px"} />
                        </Button>
                        <Button
                          sx={{
                            p: 1,
                            minWidth: "unset",
                          }}
                          title="Need any help? Contact us"
                          onClick={() => {}}
                        >
                          <img src={medicaLogo} alt="whatsapp" width={"28px"} />
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </Box>
              )}
            </>
          </Box>
        </Grid>
      </Grid>
      <Outlet />
    </>
  );
};

export default Home;
